import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import { formatColor } from "@website-builder/utilities/utils/DesignUtils.js";
import styled from "styled-components";

const box = (isMobileView, isBoxShadow = true) => `
    box-shadow:${
			isBoxShadow
				? "0px 78px 31px rgba(0, 0, 0, 0.01), 0px 44px 26px rgba(0, 0, 0, 0.02), 0px 20px 20px rgba(0, 0, 0, 0.03), 0px 5px 11px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04)"
				: "none"
		};
    border-radius: 8px;
    padding: ${isMobileView ? "3rem 2rem" : "3rem"};

    @media only screen and (max-width: ${TABLET_M_BP}px) {
        padding: 2rem 0.5rem;
    }
`;

export const HubspotFormWrapper = styled.div`
	${({ styleConfiguration }) =>
		`a  {
                color: ${
									formatColor(styleConfiguration?.linkColor) ||
									"var(--primary-neutral-nightshade-800)"
								};
            }
        a:visited{
                color: ${
									formatColor(styleConfiguration?.activeLinkColor) ||
									"var(--primary-neutral-nightshade-800)"
								};
            }
        button {
            ${
							styleConfiguration?.buttonStyle === "sharp"
								? "border-radius: 0px;"
								: ""
						}
            ${
							styleConfiguration?.buttonFontSize
								? `font-size:${styleConfiguration.buttonFontSize}px;`
								: ""
						}
            text-wrap: nowrap;
        }
        .heading-text{
             ${
								styleConfiguration?.headingSize
									? `font-size: ${styleConfiguration.headingSize}px`
									: ""
							}
        }

		@media only screen and (max-width: ${TABLET_M_BP}px) {
			.heading-text{
            ${
							styleConfiguration?.headingSizeInMobile
								? `font-size: ${styleConfiguration.headingSizeInMobile}px`
								: ""
						}
			}
        }

        `}
	${({ bgColor, isMobileView, isBoxShadow, isTextAlignmentLeft }) =>
		bgColor && bgColor != "transparent"
			? `
            background: ${bgColor || "var(--theme-primary)"};
             border-radius: 8px;
            padding: 2rem;
            ${box(isMobileView, isBoxShadow)}
            box-shadow:${({ isBoxShadow }) =>
							isBoxShadow
								? "0px 78px 31px rgba(0, 0, 0, 0.01), 0px 44px 26px rgba(0, 0, 0, 0.02), 0px 20px 20px rgba(0, 0, 0, 0.03), 0px 5px 11px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04)"
								: "none"};
            .heading-text, .subcopy-text{
                ${
									isTextAlignmentLeft
										? "text-align: left; padding-left: 2rem;"
										: ""
								}

                @media only screen and (max-width: ${TABLET_M_BP}px) {
                    padding-left: 0;
                }
            }
            ${
							isTextAlignmentLeft
								? `
            .heading-text, .subcopy-text{
                text-align: left;
                padding-left: 2rem
                @media only screen and (max-width: ${TABLET_M_BP}px) {
                    padding-left: 0.5rem;
                }
            }
            `
								: ""
						}
            &>form{
                padding: 0 1.5rem;
            }
      `
			: ``}
    form {
		max-width: ${({ maxWidth }) => maxWidth};
		margin: auto;
		${({ styleConfiguration }) =>
			`& .help-text{
                color: ${
									formatColor(styleConfiguration?.helpTextColor) ||
									"var(--primary-neutral-nightshade-800)"
								};
                font-size: ${
									styleConfiguration?.helpTextSize
										? `${styleConfiguration.helpTextSize}px`
										: "13px"
								}
            }
        .label-text, .label-text p {
            font-size: ${
							styleConfiguration?.labelSize
								? `${styleConfiguration.labelSize}px`
								: "14px"
						}
        }
        `}
		.additional-items-brand-bar {
			margin-top: 2rem;
		}
	}

	form.sb-hubspot-form
		${({ itemsOnFirstColumn }) =>
			!!itemsOnFirstColumn && `, form.sb-hubspot-form > *`} {
		${({ itemsOnFirstColumn }) =>
			!!itemsOnFirstColumn
				? `
            flex-direction: row;
            column-gap: 60px;
            .hubspot-form-column {
                flex: 1;
            }
            @media only screen and (max-width: ${TABLET_M_BP}px) {
                flex-direction: column;
            }
            .hubspot-form-column {
                .half-field {
                width: calc(50% - 8px);
                display: inline-block;
                }
                .half-field:nth-child(odd) {
                margin-right: 16px;
                }
                @media only screen and (max-width: ${TABLET_M_BP}px) {
                .half-field {
                    width: 100%;
                }
                }
            }
            `
				: null}
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		& > div {
			width: 100%;
			box-sizing: border-box;
			&.half-field {
				width: 50%;
			}

			&.half-field:nth-child(odd) {
				padding-right: 16px;
			}
			@media only screen and (max-width: ${TABLET_M_BP}px) {
				&.half-field {
					width: 100%;
				}
				padding: 2px 10px;
				width: 100%;
			}
		}
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			padding: 0;
		}
	}

	.form-consent {
		margin-top: 0.5rem;
		display: flex;
		align-items: flex-start;
		cursor: pointer;

		input {
			height: 16px;
			width: 32px;
			margin-right: 8px;
		}

		&.invalid-field input {
			outline: 2px solid var(--secondary-red-200);
		}

		span {
			text-align: left;
			&::after {
				margin-left: 0.25rem;
				content: "*";
				color: var(--secondary-red-300);
			}
		}
		a > p {
			margin: 0;
		}
	}

	.form-consent-link {
		color: ${({ darkBg }) =>
			!darkBg ? `var(--secondary-purple-300)` : `var(--secondary-purple-100)`};
		margin-bottom: 0;
	}
`;

export const FormHeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: auto;
	margin-bottom: 2rem;
	max-width: ${({ isTextAlignmentLeft, maxWidth }) =>
		isTextAlignmentLeft ? maxWidth : "480px"}!important;
`;

export const TextAreaWrapper = styled.div`
	margin-bottom: 1rem;

	.required::after {
		content: "*";
		margin-left: 0.25rem;
		color: var(--secondary-red-300);
	}
	textarea {
		color: var(--primary-neutral-nightshade-800);
		width: 100%;
		font-family: var(--desktop-body-l-regular-ff);
		font-weight: var(--desktop-body-l-regular-fw);
		line-height: var(--desktop-body-l-regular-lh);
		font-size: var(--desktop-body-l-regular-fs);
		letter-spacing: var(--desktop-body-l-regular-ls);
		text-transform: var(--desktop-body-l-regular-tc);
		text-decoration: var(--desktop-body-l-regular-td);
		color: var(--primary-neutral-nightshade-800);
		text-align: start;

		@media only screen and (max-width: ${TABLET_M_BP}px) {
			font-family: var(--mobile-body-l-regular-ff);
			font-weight: var(--mobile-body-l-regular-fw);
			line-height: var(--mobile-body-l-regular-lh);
			font-size: var(--mobile-body-l-regular-fs);
			letter-spacing: var(--mobile-body-l-regular-ls);
			text-transform: var(--mobile-body-l-regular-tc);
			text-decoration: var(--mobile-body-l-regular-td);
		}

		background: var(--primary-neutral-white);
		border: 1px solid var(--secondary-gray-medium);
		border-radius: 4px;
		padding: 0.5rem;

		::placeholder {
			color: var(--secondary-gray-dark);
		}

		:active,
		:focus {
			outline: none;
			border-color: var(--primary-forest-400);
		}
	}

	.submit-button {
		text-align: center;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-top: 1.5rem;
`;

export const SucessWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: center;
	text-align: center;
	scroll-margin: 80px;

	${({ bgColor, isMobileView, isBoxShadow }) =>
		bgColor && bgColor != "transparent"
			? `
            background: ${bgColor};
            ${box(isMobileView, isBoxShadow)}
      `
			: ``}

	.success-headline {
		display: flex;
		gap: 1rem;
		align-items: center;
	}

	button {
		width: fit-content;
	}
`;
export const ErrorSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.25rem;
	margin-top: 8px;

	${({ bgColor, isMobileView, isBoxShadow }) =>
		bgColor && bgColor != "transparent"
			? `
            background: ${bgColor};
            ${box(isMobileView, isBoxShadow)}
      `
			: ``}

	& > figure > svg > path:not(:last-child) {
		stroke: var(--secondary-red-200);
	}
	& > figure > svg > path:last-child {
		fill: var(--secondary-red-200);
	}
`;
