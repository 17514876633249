export const checkPadding = (Component) => {
	if (
		Component === "Accordion" ||
		Component === "UpcomingPrograms" ||
		Component === "QuickLinksPanel" ||
		Component === "BlogCategories" ||
		Component === "Tuition"
	)
		return false;
	else return true;
};

export const isAddMargin = (Component, section) => {
	if (
		((Component === "ApplicationForm" || Component === "LeadForm") &&
			section === "left") ||
		(Component !== "CTAButton" && section === "right")
	)
		return true;
	return false;
};

export const setCustomPadding = (component, section) => {
	let padding;
	if (section === "right") {
		switch (component) {
			case "CTAButton":
				padding = "18px";
				break;
			default:
				padding = "35px 25px 25px 25px";
		}
	}
	if (section === "body") {
		switch (component) {
			case "ApplicationFormWR":
			case "PortfolioShowcase":
				padding = "0px";
				break;
			default:
				padding = "18px 0px";
		}
	}
	return padding;
};
